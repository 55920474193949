//// Variables

// Colors
$color-yellow:              #f7a700;
$color-dark-yellow:         darken($color-yellow, 10%);
$color-black:               #17161a;
$color-brown:               #262020;
$color-dark-brown:          darken($color-brown, 4%);
$color-white:               #ffffff;
$color-anthracite:          #3f494a;

// Box-shadows
$box-shadow-black: 0 4px 74px 0 rgba(0, 0, 0, 0.21);
$box-shadow-white: 0 15px 64px 0 rgba(0, 0, 0, 0.24);

// z-index
$below-content: 5;
$content: 10;
$above-content: 15;

// Navbar
$mobileNavHeight:           80px;

// Fonts

$font-base-size: 16px;
$font-base: "freight-text-pro", serif;
$font-headings: "canela", serif;
$font-sans: "Chap", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
