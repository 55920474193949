@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(25px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes vertline {
  0% {
    opacity: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 35px;
  }
}


@keyframes scroll-line {
    0% {
        transform: translateY(0%) scaleY(0)
    }

    10% {
        transform: translateY(0%) scaleY(0.5)
    }

    30% {
        transform: translateY(50%) scaleY(1)
    }

    45% {
        transform: translateY(200%) scaleY(0.5)
    }

    55% {
        transform: translateY(200%) scaleY(0)
    }

    100% {
        transform: translateY(200%) scaleY(0)
    }
}