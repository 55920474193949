.primary-nav {
  position: relative;
  z-index: 40;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  background-color: $color-brown;
  display: none;

  @include bpm() {
    display: block;
  }

  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include bpl() {
      padding: 0 60px;
    }

    @include bpxl() {
      padding: 0 200px;
    }

    &>* {
      flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
  }

  .logo {
    flex-direction: column;

    svg {
      display: block;
      margin: 0 auto;
      margin-bottom: 8px;

      g {
        fill: $color-yellow;
      }
    }

    span {
      font-size: 14px;
      font-weight: 100;
      color: $color-white;
      font-family: $font-sans;
    }
  }

  .menu {
    li {
      display: inline-block;
      margin: 0 15px;

      &:hover {
        a {
          color: $color-yellow;

          &::before {
            transform: scaleX(1);
            transform-origin: center left;
          }

          &::after {
            opacity: 1;
          }
        }
      }

      a {
        position: relative;
        color: white;
        font-family: $font-sans;
        font-size: 12px;
        font-weight: 100;
        text-transform: uppercase;
        letter-spacing: 1px;
        padding-bottom: 8px;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          transform: scaleX(0);
          transition: 0.6s transform cubic-bezier(0.19, 1, 0.22, 1);
          transform-origin: center right;
          width: 100%;
          height: 1px;
          background-color: $color-yellow;
        }

        &::after {
          content: attr(data-title);
          position: absolute;
          top: 0;
          left: 0;
          color: $color-yellow;
          z-index: 1;
          line-height: 13px;
          transition: 0.6s clip-path cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition: 0.6s clip-path cubic-bezier(0.19, 1, 0.22, 1);
          webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
          opacity: 0;
        }
      }
    }
  }
}
