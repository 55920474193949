.underline-link {
  @include underline-link();
}

.btn {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  font-family: $font-sans;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 700;
  transition: all 0.3s ease;
  color: white;
  display: inline-block;

  &:hover {
    transition: all 0.3s ease;
    transform: translateY(-2px);
  }

  &.btn-yellow {
    background-color: $color-yellow;
    color: white;
    
    &:hover {
      background-color: $color-dark-yellow;
    }
  }

  &.btn-light-brown {
    background-color: $color-brown;

    &:hover {
      background-color: $color-yellow;
      color: white;
    }
  }

  &.btn-brown {
    background-color: $color-dark-brown;
    color: $color-yellow;
    
    &:hover {
      background-color: $color-dark-yellow;
      color: white;
    }
  }
}