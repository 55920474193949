.article-content {
  margin: 0 auto;
  width: 100%;

  @include bp-sm-ls() {
    padding: 0 80px;
    margin-bottom: 80px;
  }

  @include bpm() {
    padding: 0;
    max-width: 560px;
  }

  @include bpl() {
    max-width: 620px;
  }

  header {
    text-align: center;
    font-size: 35px;
    line-height: 40px;
    margin: 0 auto 50px auto;
    color: $color-yellow;
  }
}

.article-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 60px;
    margin-bottom: 15px;
    font-weight: 100;
    color: $color-yellow;
    font-size: 40px;

    @include bp-sm-ls() {
      font-size: 35px;
    }
  }

  p, li {
    font-size: 16px;
    line-height: 28px;
    margin: 0 0 40px 0;

    @include bp-sm-ls() {
      font-size: 18px;
      line-height: 30px;
    }

    @include bpl() {
      font-size: 20px;
      line-height: 33px;
    }
  }

  ul {
    padding-left: 20px;
    margin-bottom: 50px;

    li {
      list-style-type: disc;
      margin-bottom: 15px;
    }
  }

  blockquote {
    position: relative;
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 30px;
    font-family: $font-headings;
    padding: 50px 30px;
    line-height: normal;
    font-style: italic;
    color: $color-yellow;
    margin-bottom: 40px;

    p,h1,h2,h3,h4,h5,h6 {
      font-size: 30px;
      margin: 0;
    }
  }

  figure {
    margin: 0 0 40px;

    figcaption {
      margin-top: 15px;
      font-style: italic;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 30px auto;
    box-shadow: $box-shadow-black;
  }
}

.article-share {
  margin: 100px 0;
  text-align: center;

  h6 {
    font-size: 25px;
    margin-bottom: 25px;
    color: $color-yellow;
  }

  li {
    display: inline;
    margin: 0 20px;

    a {
      color: $color-white;
      font-family: $font-sans;
      font-weight: 700;
      font-size: 13px;

      svg {
        path {
          fill: $color-white;
        }
      }
    }
  }
}
