@font-face {
  font-family: "canela";
  src: url("../fonts/Canela-Thin.woff2") format("woff2"),
    url("../fonts/Canela-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "canela";
  src: url("../fonts/Canela-Regular.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "canela";
  src: url("../fonts/Canela-RegularItalic.otf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Chap";
  src: url("../fonts/Chap-Regular.otf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Chap";
  src: url("../fonts/Chap-Bold.otf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.dropcap {
  p:first-child::first-letter {
    color: $color-yellow;
    float: left;
    font-size: 3.4em;
    line-height: 40px;
    padding-top: 3px;
    padding-right: 10px;
    padding-left: 3px;
  }
}
