.homepage--hero {
  position: relative;
  height: calc(100vh - 110px);
  width: 100%;
  box-shadow: 0 14px 74px 0 rgba(0,0,0,.21);
  
  .homepage--hero__slide.swiper-slide-active {
    .heroSlide--content {
      display: block;
    }

    #sliderProgress {
      transition: all 8000ms ease-out;
    }
  }

  .heroSlide--content {
    position: absolute;
    display: none;
    max-height: 60%;
    z-index: 10;
    left: 0;
    bottom: 20px;
    width: 100%;
    padding: 15px;

    @include bpm() {
      left: 40px;
      bottom: 40px;
      width: 50%;
      padding: 0;
    }
  
    h2 {
      position: relative;
      font-family: $font-headings;
      font-size: 40px;
      color: $color-yellow;
      max-width: 400px;
    }

    #sliderProgress {
        display: block;
        width: 0px;
        height: 1px;
        background-color: $color-yellow;
        transition: all 8000ms ease-out;
        margin-bottom: 20px;
      }

    p {
      margin-top: 5px;
      margin-bottom: 15px;
      font-size: 16px;
      max-width: 350px;
    }
  }

  .heroSlide--photo {
    position: relative;

    img {
      object-fit: cover;
      width: 100%;
      height: 90vh;
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 70%;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to top, $color-black, rgba($color-black, 0));
    }
  }

  .pagination {
    position: absolute;
    top: 40px;
    z-index: $above-content;
    left: 40px;
    width: auto;
    bottom: initial;
    text-align: left;
    font-size: 18px;
    font-family: $font-headings;
    font-weight: 100;

    span {
      color: $color-yellow;
    }
  }

  .heroSlide--navigation {
    display: none;
    position: absolute;
    bottom: 40px;
    right: 40px;

    @include bpm() {
      display: block;
    }

    .button-next, .button-prev {
      position: relative;
      z-index: $above-content;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: inline-block;

      svg path {
        transition: all 0.6s ease-out;
      }

      &:hover {
        svg path{
          fill: $color-yellow;
          transition: all 0.6s ease-out;
        }
      }
    }

    .button-prev {
      transform: scaleX(-1);
    }
  }

  .swiper-container {
    height: 100%;
  }
}

.homepage--featured {
  margin: 70px 0 40px 0;

  .homepage--featured__photo {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .homepage--featured__content {
    background-color: $color-dark-brown;
    padding: 2em;

    @include bpl() {
      padding: 2em 5em;
    }

    h2 {
      font-size: 40px;
      color: $color-yellow;
    }

    p {
      margin-bottom: 30px;
      font-size: 18px;
    }
  }
}

.homepage--stories {
  margin: 100px 0 50px 0;

  .homepage--stories__each {
    @include bpm-max() {
      margin-bottom: 35px;
    }

    .homepageStory-link {
      position: relative;
      display: block;
      overflow: hidden;
      height: 400px;
      transition: all 0.6s ease-out;
      margin-bottom: 25px;

      .blur-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: all 0.6s ease-out;
        will-change: filter;

        &::before {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transition: all 0.6s ease-out;
        }
      }
    }

    &.hover {
      .homepageStory-link {
          transform: translateY(-5px);
          transition: all 0.6s ease-out;
          box-shadow: $box-shadow-black;
      }
    }

    &.fadeOut {
      .blur-overlay {
        filter: blur(5px);
        transition: all 0.6s ease-out;

        &::before {
          background-color: rgba(black, 0.5);
          transition: all 0.6s ease-out;
        }
      }

      .homepage--stories__content {
        opacity: 0.3;
        transition: opacity 0.6s ease;
      }
    }
  }

  h1 {
    text-align: center;
    color: $color-yellow;
    margin-bottom: 50px;
    font-size: 50px;
  }

  .homepage--stories__content {
    text-align: center;
    transition: opacity 0.6s ease;

    h2 {
      font-size: 2.8em;
      a {
        color: $color-yellow;
      }
    }

    h3 {
      position: relative;
      font-family: $font-sans;
      font-size: 13px;
      letter-spacing: 1px;
      margin-bottom: 25px;
      color: white;
      text-transform: uppercase;
    }

    .read-more {
      font-family: $font-headings;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.homepage--moreStories {
  margin-bottom: 50px;
  text-align: center;
}
