.page-template-template-events {

  .events-list {
    
    @include bpm() {
      margin: 0 50px;
    }

    @include bpl() {
      margin: 0 120px;
    }
  }

  .event {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 50px;
    background-color: $color-dark-brown;
    cursor: pointer;
    transition: all .2s ease-out;

    .event-click {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 0;
      z-index: 1;
      cursor: pointer;
    }

    &:hover {
      transform: translate3d(0,-5px,0);
      box-shadow: 0 20px 25px 0 rgba(0,0,0,.15);
      transition: all .2s ease-out;

      .event-photo {
        &::after {
          background-color: rgba($color-yellow, 0.7);
          transition: all .2s ease-out;
        }

        .icon-circle-more {
          transform: translate(-50%, -50%) scale3d(1,1,1);
        }
      }
    }

    .event-content {
      padding: 50px;

      h2 {
        margin-bottom: 20px;
        font-size: 25px;
        color: $color-yellow;
      }

      p {
        font-size: 18px;
        margin-bottom: 50px;
        color: white;

        @include bpm() {
          font-size: 20px;
        }
      }

      li {
        display: inline-block;
        font-family: $font-sans;
        margin-right: 25px;
        margin-bottom: 5px;
        color: white;
        width: 100%;

        @include bpm() {
          //display: inline;
          //margin-bottom: 0px;
        }

        span, p {
          display: inline;
          font-size: 16px;
          color: white;
        }

        span {
          font-weight: 700;
        }
      }
    }

    .event-photo {
      position: relative;
      display: none;
      width: 230px;
      min-width: 230px;

      .icon-circle-more {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 62px;
        height: 62px;
        opacity: 1;
        z-index: 1;
        transform: translate(-50%, -50%) scale3d(0,0,1);
        transition: transform .22s ease-out;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          border-radius: 50% 50%;
          background-color: $color-yellow;
          transition: transform .22s ease-out;
        }

        .icon-svg {
          position: absolute;
          left: 18px;
          top: 18px;
          width: 26px;
          height: 26px;
          pointer-events: none;
          transform: translate3d(2px,0,0) rotate(-90deg) scaleX(1);
          transition: transform .26s ease-in-out;
        }
      }

      &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transition: all .2s ease-out;
      }

      @include bpm() {
        display: block;
      }

      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
}