footer {
  margin-top: 100px;
  .footer-logo {
    margin: 50px 0;
    text-align: center;
    
    a {
      display: flex;
      flex-direction: column;
      opacity: 0.6;
    }

    svg {
      display: block;
      margin: 0 auto;
      margin-bottom: 4px;
      transform: rotate(-20deg);
      
      g {
         fill: $color-yellow;
      }
    }

    span {
      font-size: 12px;
      color: $color-white;
      font-family: $font-sans;
    }

    h4 {
      font-size: 18px;
      font-weight: 100;
    }
  }

  .footer-col {
    text-align: center;
    margin-bottom: 40px;

    @include bpm() {
      text-align: center;
      margin-bottom: 0;
    }

    h5 {
      font-size: 18px;
      font-weight: 100;
      margin-bottom: 5px;
      color: $color-yellow;
    }

    a {
      color: white;
      font-family: $font-sans;
      font-size: 13px;
    }

    li {
          padding: 3px 0;
    }
  }

  .terms {
    margin-top: 30px;
    text-align: center;
    font-family: $font-sans;

    li {
      display: inline;
      margin: 0 15px;
    }
  }

  .copyright {
    margin: 30px 0;
    font-size: 14px;
    text-align: center;
    font-family: $font-sans;

    a {
      color: white;
    }
  }
}
