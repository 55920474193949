.page-title {
 color: $color-yellow;
 text-align: center;
 font-size: 3.4em;
 line-height: 1em;
 margin-bottom: 50px;
 animation: fadein 1s forwards;

 @include bpm() {
   font-size: 5em;
 }
}

.slideUp {
  animation: slideUp 3s forwards;
}

.page-template-default {
  article {
    margin-top: 60px;
  }

  img {
    box-shadow: $box-shadow-black;
  }
}