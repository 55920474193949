@mixin bp-sm-ls () { /* smartphone-landscape */
    @media screen and (min-width: 600px) {
        @content;
    }
}

@mixin bps() {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin bpm() {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin bpl() {
    @media screen and (min-width: 992px) {
        @content;
    }
}

@mixin bpxl() {
    @media screen and (min-width: 1200px) {
        @content;
    }
}

@mixin bpm-max() {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin bps-max() {
    @media screen and (max-width: 475px) {
        @content;
    }
}
