.single-stories {
  .singleStory--title {
    text-align: center;

    h1 {
      color: $color-yellow;
      font-size: 3.4em;
      line-height: 1em;
      animation: fadein 1s forwards;

      @include bpm() {
        font-size: 5em;
      }
    }

    h2 {
      opacity: 0;
      animation: fadein 4s forwards;
      animation-delay: 2s;
      font-family: $font-sans;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    .vert-line {
      position: relative;
      display: block;
      margin: 0 auto;
      margin-bottom: 10px;
      height: 35px;
      width: 1px;
      
      &::after {
        position: absolute;
        content:'';
        width: 100%;
        left: 0;
        top: 0;
        animation: vertline 1s forwards;
        animation-timing-function: cubic-bezier(0.21, 0.61, 0.35, 1);
        animation-delay: 1.2s;
        background-color: $color-white;
      }
    }
  }

  article {
    margin-bottom: 100px;

    figure {
      max-width: 100%;
      margin-top: 50px;
      margin-bottom: 60px;
      animation: slideUp 3s forwards;
      opacity: 0;
      animation-delay: 3s;

      img {
        max-width: 100%;
        height: auto;
        box-shadow: $box-shadow-black;
      }
    }
  }

  .related {
    margin-bottom: 100px;
    h3 {
      text-align: center;
      font-size: 40px;
      margin-bottom: 20px;
    }
    #relatedStories {
      position: relative;
      width: 100%;
      padding: 50px 0;

      @include bpm() {
        width: 70%;
      }

      .related__thumbnail {
        width: 70%;
        height: 300px;
        overflow: hidden;

        @include bpm() {
          width: 420px;
          height: 500px;
        }

        &.swiper-slide-active {
          box-shadow: $box-shadow-black;
          a {
            &::after {
              background-position: 0 -70px;
            }
          }
        }

        a {
          display: block;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            z-index: $content;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: linear-gradient(
              180deg,
              transparent 30%,
              rgba(0, 0, 0, 0.75) 75%
            );
            background-size: auto 200%;
            background-position: 0 100%;
            transition: background-position 0.5s;
          }
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 300px;

          @include bpm() {
            height: 500px;
          }
        }

        &.swiper-slide-active {
          .related__details {
            transition: all 0.5s ease-in-out;
            opacity: 1;
            overflow: hidden;

            h1,
            h2 {
              transform: translateY(0);
              transition: all 0.6s ease-in-out;
            }

            h2 {
              transition-delay: 0.2s;
              font-family: $font-sans;
              font-size: 12px;
              text-transform: uppercase;
              letter-spacing: 1px;
            }
          }
        }

        .related__details {
          position: relative;
          z-index: $above-content;
          transition: all 0.5s ease-in-out;
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 300px;
          top: 0;
          left: 0;

          @include bpm() {
            height: 500px;
          }

          h1,
          h2 {
            position: absolute;
            left: 20px;
            transition: all 0.5s ease-in-out;
          }

          h1 {
            color: $color-yellow;
            transform: translateY(100px);
            bottom: 45px;
            font-size: 30px;

            @include bpm() {
              font-size: 45px;
            }
          }

          h2 {
            transform: translateY(120px);
            bottom: 25px;
            color: white;
            font-size: 16px;

            @include bpm() {
              //font-size: 30px;
            }
          }
        }
      }

      .button-prev,
      .button-next {
        position: absolute;
        z-index: $above-content;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        cursor: pointer;
        background-color: $color-yellow;
        background-image: url("../images/arrow.svg");
        background-size: 30px 30px;
        background-repeat: no-repeat;
        background-position: 7px 7px;
      }

      .button-prev {
        left: 0;
        transform: translateY(-50%) scaleX(-1);
      }

      .button-next {
        right: 0;
      }
    }
  }
}
