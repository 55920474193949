*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}

@-ms-viewport {
  width: device-width;
}

html {
  min-height: 100%;
  height: 100%;
  -ms-overflow-style: scrollbar;
}

/** =RENDERING **/
html,
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
  -moz-font-feature-settings: "smcp=1, onum=1, frac=1, kern=1, liga=1, dlig=1, swsh=1";
  -ms-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
  -o-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
  -webkit-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1,
    "liga" 1, "dlig" 1, "swsh" 1;
  font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
  font-feature-settings: "liga", "kern";
}

/* Chrome doesn't assume text rendering for form elements */
input,
textarea,
select,
button {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
  -moz-font-feature-settings: "smcp=1, onum=1, frac=1, kern=1, liga=1, dlig=1, swsh=1";
  -ms-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
  -o-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
  -webkit-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1,
    "liga" 1, "dlig" 1, "swsh" 1;
  font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
    "dlig" 1, "swsh" 1;
}

.text-subs,
.sub,
sub {
  -moz-font-feature-settings: "subs" 1;
  -moz-font-feature-settings: "subs=1";
  -ms-font-feature-settings: "subs" 1;
  -o-font-feature-settings: "subs" 1;
  -webkit-font-feature-settings: "subs" 1;
  font-feature-settings: "subs" 1;
}
.text-sups,
.sup,
sup {
  -moz-font-feature-settings: "sups" 1;
  -moz-font-feature-settings: "sups=1";
  -ms-font-feature-settings: "sups" 1;
  -o-font-feature-settings: "sups" 1;
  -webkit-font-feature-settings: "sups" 1;
  font-feature-settings: "sups" 1;
}

body {
  height: 100%;
  background-color: $color-brown;
  color: $color-white;
  font: $font-base-size $font-base;
  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%; /* specifies a size adjustment for displaying text content in IE on Windows phones */
  -webkit-text-size-adjust: 100%; /* specifies a size adjustment for displaying text content in Safari on iPhone */
  -webkit-touch-callout: none; /* disables the default callout shown when you touch and hold a touch target */
  -webkit-overflow-scrolling: touch; /* controls whether or not touch devices use momentum-based scrolling for the given element */
  -webkit-text-size-adjust: none;
  transition: all 0.3s ease;

  &.of-hidden {
    overflow: hidden;
  }
}

a,
a:link,
a:visited,
a:active {
  color: $color-yellow;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-headings;
  font-weight: 100;
  margin: 0;
  line-height: 1.25em;
  
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin-top: 1.5em;
  margin-bottom: 0;
  &::selection {
    background: $color-yellow; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    background: $color-yellow; /* Gecko Browsers */
  }
  &:first-child {
    margin-top: 0;
  }
  &.margin-bottom {
    margin-bottom: 1.5em;
  }
}

figure {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.is-mobile {
  display: block;
}
.img-responsive {
  height: auto;
  max-width: 100%;
}
.img-cover {
  background: {
    size: cover;
    position: center center;
  }
}

.block-dark {
  background-color: $color-black;
  color: white;
}

.block-content {
  width: 80%;
  margin: 0 auto;

  @include bpm() {
    width: 70%;
  }

  @include bpl() {
    width: 60%;
  }

  @include bpxl() {
    width: 40%;
  }
}

.p-100 {
  padding: 100px 0;
}

.p-50 {
  padding: 50px 0;
}

.t-center {
  text-align: center;
}

@include bpm() {
  .is-mobile {
    display: none;
  }
}

[data-clickbox] {
  cursor: pointer;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE antialiasing fix - https://gist.github.com/dalethedeveloper/1846552 */
  * {
    text-shadow: 1px 1px 1px rgba($color-anthracite, 0.08);
    font-smooth: always;
  }
}
