.day-n-night {
  display: block;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;

  svg {
    margin: 0 auto;
    display: block;
    user-select: none;

    &#icon-day {
      display: none;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease;
    }
    path {
      fill: white;
    }
  }
}

body.day {
  background-color: white;
  color: $color-black;
  transition: all 0.3s ease;

  .primary-nav {
    background-color: white;
    border-bottom: 1px solid lighten($color: $color-black, $amount: 80);

    .logo svg path {
      fill: $color-black;
    }

    .menu li a {
      color: $color-black;
    }
  }

  .day-n-night {
    svg {
      &#icon-day {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease;

        path {
          fill: $color-yellow;
        }
      }

      &#icon-night {
        display: none;
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &.single-stories {
    article img {
      box-shadow: $box-shadow-white;
    }

    .singleStory--title .vert-line {
      background-color: black;
    }

    .related #relatedStories .related__thumbnail.swiper-slide-active {
      box-shadow: $box-shadow-white;
    }
  }

  footer .footer-col a {
    color: $color-black;
  }

  .article-share {
    svg path {
      fill: $color-black;
    }
  }
}
