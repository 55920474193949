.mobile-nav {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: $above-content;
  width: 100%;
  height: $mobileNavHeight;
  padding: 0 20px;
  background-color: $color-brown;
  top: 0;
  transition: all 0.3s ease-in-out;

  &.no-box-shadow {
    box-shadow: none !important;
    transition: all 0.3s ease-in-out;
  }

  @include bpm() {
    display: none;
  }

  &.nav-down {
    box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.34);
  }

  .logo {
    position: relative;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $font-sans;
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 100;


    svg {
      display: block;
      width: 35px;
      height: auto;
      //margin-right: 5px;
      margin-bottom: 6px;

      g {
        fill: $color-yellow;
      }
    }
  }

  .mobile-nav__toggle {
    display: flex;
    align-items: center;

    &.menu--visible {
      span {
        opacity: 0;
        transition: opacity 0.7s ease;
      }
    }

    span {
      text-transform: uppercase;
      font-family: $font-sans;
      margin-top: 5px;
      font-weight: 100;
      margin-right: 10px;
    }

    .hamburguer {
      position: relative;
      display: flex;
      flex-direction: column;
      z-index: 20;
      width: 35px;
      height:35px;

      .line {
        height: 1px;
        background: white;
        margin: 5px 0;
        transition: all 300ms ease-out;
        opacity: 1;
        width: 35px;
      }
    }

    &.menu--visible .line {
      &:nth-child(1) {
        transform: rotateZ(45deg);
        transform-origin: 3px 7px;
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotateZ(-45deg);
        transform-origin: 6px -4px;
      }
    }
  }
}

.mobile-navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0 45px;
  z-index: $above-content;
  background-color: $color-brown;
  transform: translateX(100%) translateZ(0);
  transition: transform 0.68s cubic-bezier(0.64, 0.04, 0.35, 1);

  @include bpm() {
    width: 45vw;
    left: auto;
    right: 0;
    display: block;
  }
  
  span {
    text-transform: uppercase;
    letter-spacing: 7px;
    display: block;
    margin-bottom: 35px;
    font-family: $font-sans;
    color: $color-white;
  }

  .menu {
    padding-top: 75px;
    li {
      display: block;
      margin-bottom: 35px;
      transform: translateX(25px);
      transition: transform 0.7s ease;
    }

    a {
      font-family: $font-headings;
      font-weight: 100;
      font-size: 35px;
      color: $color-yellow;
      display: block;
    }
  }

  &.menu--visible {
    transform: translateX(0) translateZ(0);

    .menu {
      li {
        transition: transform 0.7s ease;
        transform: translateX(0);

        &:nth-child(1) {
          -webkit-transition-delay: 100ms;
          -moz-transition-delay: 100ms;
          -o-transition-delay: 100ms;
          transition-delay: 100ms;
        }

        &:nth-child(2) {
          -webkit-transition-delay: 200ms;
          -moz-transition-delay: 200ms;
          -o-transition-delay: 200ms;
          transition-delay: 200ms;
        }

        &:nth-child(3) {
          -webkit-transition-delay: 300ms;
          -moz-transition-delay: 300ms;
          -o-transition-delay: 300ms;
          transition-delay: 300ms;
        }

        &:nth-child(4) {
          -webkit-transition-delay: 400ms;
          -moz-transition-delay: 400ms;
          -o-transition-delay: 400ms;
          transition-delay: 400ms;
        }

        &:nth-child(5) {
          -webkit-transition-delay: 500ms;
          -moz-transition-delay: 500ms;
          -o-transition-delay: 500ms;
          transition-delay: 500ms;
        }
      }
    }
  }
}