body.menu--visible {
  .global-wrapper {
    transform: translateX(-25%) translateZ(0);
  }
}

body:not(.home) {
  .global-wrapper {
    padding-top: calc(#{$mobileNavHeight} + 50px);

    @include bpm() {
      padding-top: 50px;
    }
  }
}

.global-wrapper {
  padding-top: $mobileNavHeight;

  @include bps() {
    transition: transform 0.68s cubic-bezier(0.64, 0.045, 0.35, 1);
    transform: translateX(0) translateZ(0);
  }

  @include bpm() {
    padding-top: 0;
  }
}